import React, { useEffect, useState, useMemo } from 'react'
import axios, { AxiosResponse } from 'axios'
import { DOLAR_QUOTATION } from '../../../../../../config/api/Urls'

import { ContentSection, CotacaoValorContainer } from './style'

// Components
import { subDays, format } from 'date-fns'
import { DatePicker } from '@interco/inter-ui/components/DatePicker'

// API Interface
interface IData {
  mensagem: string;
}

const ValorCotacaoDolar = () => {
  const [ date, setDate ] = useState<Date | null>(null)
  const [ error, setError ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ dollarValue, setDollarValue ] = useState(0.00)

  useEffect(() => {
    // evita problemas de reconciliação
    setDate(subDays(new Date(), 1))
  }, [])

  useEffect(() => {
    if (date === null) return
    setLoading(true)
    setError(false);
    (async () => {
      try {
        const response: AxiosResponse<IData> = await axios.get(`${DOLAR_QUOTATION}?moeda=dolar&dataCotacao=${format(date, 'dd/MM/uuuu')}`)
        const { mensagem }: IData = response.data
        const replaceSpecialCharacter = mensagem.replace(/\u00A0/g, ' ')
        const value = replaceSpecialCharacter.match(/R\$ ([0-9]+,[0-9]{2})/)

        value && setDollarValue(parseFloat(value[1].replace(',', '.')))

        setLoading(false)
      } catch (e) {
        setError(true)
        setLoading(false)
      }
    })()
  }, [ date ])

  const today = useMemo(() => new Date(), [])
  const startDate = useMemo(() => subDays(today, 180), [])
  const endDate = useMemo(() => subDays(today, 1), [])

  return (
    <ContentSection>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-6 mb-3 mt-5 mt-lg-0 mb-lg-0'>
            <p className='text-gray--700 fs-18 lh-22'>
              Nas suas compras internacionais no crédito com o cartão Inter Mastercard, a conversão dos
              valores é feita com base na cotação do dólar no dia em que você comprou. A cotação de hoje
              fica disponível no próximo dia útil, e para compras no final de semana e feriados
              é considerada a cotação do último dia útil.
            </p>
            <p><b className='text-gray--700 fs-18 lh-22'>Escolha o dia no calendário para ver o valor da cotação:</b></p>
            <CotacaoValorContainer>
              <p className='text-gray--800 fs-16 lh-22 text-center'>
                Cotação do dólar no dia {date ? format(date, 'dd/MM/uuuu') : '-'}
              </p>
              <div className='fs-32 lh-24 text-orange--dark text-center'>
                {error && <span className='fs-14'>Ocorreu um erro! Tente novamente mais tarde.</span>}
                <b>{loading ? (
                  <span className='fs-14'>carregando...</span>
                  ) : !error && dollarValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </b>
              </div>
            </CotacaoValorContainer>
          </div>
          <div className='col-12 col-lg-6 d-flex justify-content-center mb-5 mb-lg-0 content__section__datePicker'>
            {date && (
              <DatePicker
                value={date}
                minDate={startDate}
                maxDate={endDate}
                onChange={(a: Date | undefined) => setDate(a as Date)}
                disabledKeyboardNavigation
                inline
              />
            )}
          </div>
        </div>
      </div>
    </ContentSection>
  )
}

export default ValorCotacaoDolar
