import React from 'react'
import { ContentHeader } from './style'
import Icon from 'src/components/UI/MarkdownIcon'

const Hero = () => {
  return (
    <ContentHeader>
      <div className='container'>
        <div className='col-12 pl-0 pb-2'>
          <div className='d-flex align-items-center mb-3'>
            <Icon directory='v2/pagamentos' icon='cards' width={26} height={26} color='white' />
            <p className='text-white fs-sm-18 fs-16 fw-300 ml-2 mb-0'>Cartões</p>
          </div>
          <h1 className='text-white fs-30 fs-md-48 mb-3 mb-lg-5 fw-600'>Cotação do dolar para compras no crédito</h1>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Hero
