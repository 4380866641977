import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import bgImageDesktop from './../../assets/images/bg-cotacao-dolar-header2.png'

export const ContentHeader = styled.section`
  display: flex;
  align-items: end;
  height: 470px;
  width: auto;
  background-image: url(${bgImageDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media ${device.tablet} {
    background-image: url(${bgImageDesktop});
    background-repeat: no-repeat;
    background-position: center;
  }
`
