import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import Header from './sections/hearder/_hero'
import ValorCotacaoDolar from './sections/valor-cotacao-dolar/_valor-cotacao-dolar'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const CotacaoDolar = () => {
  return (
    <Layout pageContext={pageContext}>
      <Wrapper>
        <Header />
        <ValorCotacaoDolar />
      </Wrapper>
    </Layout>
  )
}

export default CotacaoDolar
