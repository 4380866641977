import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { gray } from 'src/styles/colors'

export const ContentSection = styled.div`
  @media ${device.desktopLG} {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .cards {
    margin-top: -160px;

    @media ${device.tablet} {
      margin-top: 0;
    }
  }

  .content__section__datePicker{
    .react-datepicker{
      width: 350px;
      margin: 0 auto;
      z-index: -10;
    }
  }
`

export const CotacaoValorContainer = styled.div`
  background: ${gray[400]};
  border-radius: 4px;
  text-align: center;
  padding: 24px 10px;
`
